<template>
  <v-card
    :variant="safeColor ? 'tonal' : 'flat'"
    :color="safeColor"
    class="text-center ma-2"
    density="compact"
    v-bind="$attrs"
  >
    <v-toolbar
      v-if="safeHeader"
      :color="safeColor"
      class="text-left"
      density="compact"
      :title="safeHeader"
    />

    <v-card-text>
      <v-avatar
        :size="72"
        variant="tonal"
        :color="safeColor"
      >
        <v-icon
          :size="48"
          :icon="safeIcon"
        />
      </v-avatar>

      <h3 class="text-h3 ma-4">
        {{ safeTitle }}
      </h3>

      <h4 class="text-h5 ma-4">
        {{ safeDescription }}
      </h4>

      <slot>
        <p>{{ safeNote }}</p>
      </slot>
    </v-card-text>

    <v-card-actions class="justify-center mb-4">
      <mtf-actions
        :actions="actions"
        class="mt-8 d-flex align-center justify-center"
        @action="$emit('action', $event)"
      />
      <!-- <v-btn text @click="dialog.value = false">Close</v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import MtfActions from '@matterific/components/Actions';
import { useDisplay } from 'vuetify';

export default defineComponent({
  name: 'MtfEmpty',
  components: {
    MtfActions
  },
  inheritAttrs: true,
  customOptions: {},
  props: {
    header: String,
    icon: String,
    title: String,
    description: String,
    note: String,
    actions: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['action'],

  setup() {
    const { mobile } = useDisplay();
    return { mobile };
  },

  computed: {
    safeHeader() {
      return this.header || '';
    },
    safeIcon() {
      return this.icon || 'mdi-magnify';
    },
    safeTitle() {
      return this.title || 'Sorry!';
    },
    safeDescription() {
      return this.description || 'We could not find anything';
    },
    safeNote() {
      return this.note || 'Try clearing any filters or search terms or Try adding your first';
    },
    safeColor() {
      return this.$attrs?.color || '';
    }
  }
});
</script>
